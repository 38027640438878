import React, { Box, type ChakraProps } from '@chakra-ui/react'
import { type PropsWithChildren } from 'react'

type PanelContentProps = ChakraProps & PropsWithChildren

function PanelContent (props: PanelContentProps): JSX.Element {
	const {
		children,
		...rest
	} = props
	return (
		<Box {...rest}>
			{children}
		</Box>
	)
}

export default PanelContent

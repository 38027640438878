import { createBrowserRouter } from 'react-router-dom'
import ErrorPage from './pages/error/Error'
import * as React from 'react'
import { Applications } from './pages/keysmanagement/list'
import { LoggedInLayout } from './layouts/LoggedInLayout'
import Login from './pages/login/Login'
import { LoggedOutLayout } from './layouts/LoggedOutLayout'
import Overview from './pages/overview'
import { DocLink } from './pages/keysmanagement/components/DocLink'

export const router = createBrowserRouter([
	{
		path: '/',
		element: <LoggedOutLayout/>,
		errorElement: <LoggedOutLayout><ErrorPage/></LoggedOutLayout>,
		children: [
			{
				path: '',
				element: <Login/>
			},
			{
				path: 'login',
				element: <Login/>
			}
		]
	},
	{
		path: '/',
		element: <LoggedInLayout/>,
		errorElement: <ErrorPage/>,
		children: [
			{
				path: 'overview',
				element: <Overview/>
			},
			{
				path: 'key-management',
				element: <Applications/>
			},
			{
				path: 'documentation',
				element: <DocLink/>
			}
		]
	}
])

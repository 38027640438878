import { useLocation, Navigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import React, { Flex, Spinner } from '@chakra-ui/react'

const PERMISSIONS_KEY = 'https://vedadata.com/permissions'
const AUTH_MGMT_CLAIM = process.env.REACT_APP_AUTH_MGMT_CLAIM ?? 'manage:clientApplications'

export function RequiresAuth ({ children }: { children: JSX.Element }): JSX.Element {
	const {
		isAuthenticated,
		isLoading,
		logout,
		loginWithRedirect,
		getIdTokenClaims,
		user
	} = useAuth0()
	const location = useLocation()
	if (isLoading) {
		return (
			<Flex justifyContent="center" alignItems="center" height="100vh">
				<Spinner height={'80px'} width={'80px'} color="blue.500"/>
			</Flex>
		)
	}
	if (isAuthenticated) {
		getIdTokenClaims().then(claims => {
			if (claims == null) { return false }
			const permissions: string[] = claims[PERMISSIONS_KEY]
			if (!permissions.includes(AUTH_MGMT_CLAIM)) {
				console.warn(`user ${user?.email ?? 'unknown'} does not have sufficient permissions to access key management`)
				logout().catch(console.error)
				loginWithRedirect().catch(console.error)
			}
		}).catch(console.error)

		return children
	} else {
		logout().catch(console.error)
		loginWithRedirect().catch(console.error)
		return <Navigate to="/login" state={{ from: location }} replace/>
	}
}

import { Button, Flex, Heading, HStack, Spacer, Text } from '@chakra-ui/react'
import IconBox from '../../../components/Icons/IconBox'
import { ApiLogo } from '../../../components/Icons/Icons'
import { AddIcon } from '@chakra-ui/icons'
import React from 'react'

export function TopNavBar (props: { handleCreate: () => void }): JSX.Element {
	const { handleCreate } = props
	return (
		<Flex as="nav" mb="60px" alignItems="center">
			<Flex direction="column">
				<HStack spacing="20px">
					<IconBox bg="#ECE7FE" br="10px" height="45px" width="45px">
						<ApiLogo/>
					</IconBox>
					<Heading as="h2" fontSize="1.5em">APIs</Heading>
				</HStack>
				<Text pt={'5px'}>Define APIs that you can consume from your authorized applications.</Text>
			</Flex>
			<Spacer/>
			<Button
				variant={'strong'}
				leftIcon={<AddIcon/>}
				onClick={handleCreate}
				borderRadius={'5px'}
				name='createApplication'
			>Create application</Button>
		</Flex>
	)
}

import React, { useEffect, useState } from 'react'
import { Modal, ModalContent, ModalOverlay, useDisclosure, useToast, type UseToastOptions } from '@chakra-ui/react'
import { useRestAPI } from '../../hooks/useFetch'
import { useAuth0 } from '@auth0/auth0-react'
import { AppForm } from './components/appForm'
import { TopNavBar } from './components/TopNav'
import { AppTable } from './components/appTable'

export interface Application {
	id: number
	name: string
	description: string
	client_id: string
	client_secret: string
	scopes: string[]
}

export const toastDefaults: UseToastOptions = {
	title: 'Application',
	duration: 5000,
	isClosable: true,
	position: 'top-right',
	status: 'success'
}

export function Applications (): JSX.Element {
	const [applications, setApplications] = useState<Application[]>([])
	const [accessToken, setAccessToken] = useState<string>('')
	const {
		isOpen,
		onOpen,
		onClose
	} = useDisclosure()
	const [selectedApplication, setSelectedApplication] = useState<Application | null>(null)
	const toast = useToast()

	const {
		getAccessTokenSilently,
		isAuthenticated
	} = useAuth0()

	const keyMgmtRestAPI = useRestAPI(process.env.REACT_APP_KEY_MGMT_URI ?? '')
	const authRestAPI = useRestAPI(process.env.REACT_APP_AUTH_URI ?? '')

	const requestHeaders = {
		Authorization: 'Bearer ' + accessToken
	}

	async function fetchApps (): Promise<void> {
		const response = await keyMgmtRestAPI.get<Application[]>('/client-applications', requestHeaders)
		if (response.error != null) {
			toast({
				title: 'Something went wrong!',
				status: 'error',
				description: 'Try again later.',
				duration: 10000,
				isClosable: true,
				position: 'top'
			})
		} else {
			setApplications(response.data ?? [])
		}
	}

	useEffect(() => {
		if (isAuthenticated) {
			void getAccessTokenSilently().then((token) => {
				setAccessToken(token)
			})
		}

		if (accessToken !== '') {
			fetchApps().then(() => {
			}).catch((error) => {
				console.log(error)
			})
		}
	}, [isAuthenticated, accessToken])

	const handleEdit = (application: Application): void => {
		setSelectedApplication(application)
		onOpen()
	}
	const handleRotate = (id: number): void => {
		const answer = window.confirm('This will rotate the current client secret. Existing tokens will continue to function until expiry.  Continue?')
		if (!answer) {
			return
		}
		void (async (): Promise<void> => {
			await keyMgmtRestAPI.post(`/client-applications/${id}/rotate-secret`, null, requestHeaders)
			toast({
				...toastDefaults,
				description: 'Application secret rotated successfully'
			})
			await fetchApps()
		})()
	}
	const handleDelete = (id: number): void => {
		const answer = window.confirm('Are you sure you want to delete this application?')
		if (!answer) {
			return
		}
		void (async (): Promise<void> => {
			await keyMgmtRestAPI.remove(`/client-applications/${id}`, requestHeaders)
			toast({
				...toastDefaults,
				description: 'Application deleted Successfully'
			})
			await fetchApps()
		})()
	}

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
		e.preventDefault()
		let data
		void (async (): Promise<void> => {
			let description = 'Application created Successfully'
			if ((selectedApplication?.client_id) != null) {
				description = description.replace('created', 'updated')
				const req = {
					name: selectedApplication?.name,
					description: selectedApplication?.description,
					scopes: selectedApplication?.scopes
				}
				data = await keyMgmtRestAPI.patch<Application>(`/client-applications/${selectedApplication?.client_id}`, JSON.stringify(req), requestHeaders)
			} else {
				data = await keyMgmtRestAPI.post<Application>('/client-applications', JSON.stringify(selectedApplication), requestHeaders)
			}
			onClose()
			if (data == null) {
				return
			}
			toast({
				...toastDefaults,
				description
			})
			await fetchApps()
		})()
	}
	const handleCreate = (): void => {
		setSelectedApplication(null)
		onOpen()
	}
	const getAppAccessToken = (application: Application): void => {
		const urlencoded = new URLSearchParams()
		urlencoded.append('client_id', application.client_id)
		urlencoded.append('client_secret', application.client_secret)
		urlencoded.append('grant_type', 'client_credentials')

		void (async (): Promise<void> => {
			if (authRestAPI.loading) {
				return
			}
			const data = await authRestAPI.post<{
				access_token: string
			}>('/token', urlencoded, {
				'Content-Type': 'application/x-www-form-urlencoded'
			})
			void (data.data?.access_token != null && navigator.clipboard.writeText(data.data.access_token))
			toast({
				...toastDefaults,
				description: 'Access Token copied to the clipboard'
			})
		})()
	}

	return (
		<>
			<TopNavBar handleCreate={handleCreate}/>
			<AppTable
				applications={applications}
				handleEdit={handleEdit}
				handleRotate={handleRotate}
				handleDelete={handleDelete}
				isLoading={keyMgmtRestAPI.loading}
				getAppAccessToken={getAppAccessToken}
			/>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay/>
				<ModalContent>
					<AppForm
						selectedApplication={selectedApplication}
						setSelectedApplication={setSelectedApplication}
						loading={keyMgmtRestAPI.loading}
						handleSubmit={handleSubmit}
					/>
				</ModalContent>
			</Modal>
		</>
	)
}

import React from '@chakra-ui/react'
import { useAuth0 } from '@auth0/auth0-react'
import { Navigate } from 'react-router-dom'

export default function Login (): JSX.Element {
	const { logout, loginWithRedirect, isAuthenticated } = useAuth0()
	if (isAuthenticated) {
		return <Navigate to={'/overview'} />
	} else {
		logout().catch(console.error)
		loginWithRedirect().catch(console.error)
		return <></>
	}
}

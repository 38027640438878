import { ExternalLinkIcon } from '@chakra-ui/icons'
import React, { Flex, IconButton, List, Menu, MenuButton, MenuItem, MenuList, Spacer } from '@chakra-ui/react'
import { DropDownLogo, EngineIcon, VedaLogo } from '../Icons/Icons'
import { DocLink } from '../../pages/keysmanagement/components/DocLink'

export default function Navbar (): JSX.Element {
	return (
		<Flex as="nav" p="10px" alignItems="center" bg="#1B0462">
			<VedaLogo w="65px" h="auto" ml="10px"/>
			<Spacer/>
			<Menu>
				<MenuButton
					as={IconButton}
					aria-label="Options"
					icon={<DropDownLogo/>}
					variant="primary"
					bg="primary.600"
					border={'none'}
					borderRadius={'10px'}
					mr="30px"
				/>
				<MenuList>
					<MenuItem icon={<ExternalLinkIcon/>} as="a" href="#">
						Veda Platform
					</MenuItem>
					<MenuItem icon={<EngineIcon/>} as="a" href="/key-management">
						Key Management
					</MenuItem>
					<MenuItem><List><DocLink/></List></MenuItem>
				</MenuList>
			</Menu>
		</Flex>
	)
}

import { Button, Flex, Link, List, ListIcon, ListItem } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { OverviewIcon, EngineIcon, LogoutIcon } from '../Icons/Icons'
import { DocLink } from '../../pages/keysmanagement/components/DocLink'

const linkStyle = {
	p: '8px 12px',
	width: '100%',
	display: 'block',
	color: '#2D3748'
}

export default function Sidebar (): JSX.Element {
	const { logout } = useAuth0()

	return (
		<Flex direction={'column'} height={'100%'}>
			<List fontSize="1.2em" spacing={4} color="#0D0038">
				<ListItem>
					<Link as={NavLink} to="/overview" {...linkStyle} >
						<ListIcon as={OverviewIcon}/>
						Overview
					</Link>
				</ListItem>
				<ListItem>
					<Link data-testid="sidebar-keymgmt-link" as={NavLink} to="/key-management" {...linkStyle}>
						<ListIcon as={EngineIcon}/>
						Keys Management
					</Link>
				</ListItem>
				<ListItem>
					<DocLink data-testid="sidebar-doc-link" {...linkStyle} />
				</ListItem>
			</List>

			<Button
				leftIcon={<LogoutIcon/>}
				alignSelf={'center'}
				size="md"
				variant="ghost"
				onClick={() => {
					const redirectTO = process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_TO ?? '/'
					logout({ logoutParams: { returnTo: `${window.location.origin}${redirectTO}` } }).catch(console.error)
				}}
				height={'50px'}
				borderTop={'1px solid #E2E8F0'}
				width={'100%'}
				marginTop={'auto'}
			>
				Logout
			</Button>
		</Flex>
	)
}

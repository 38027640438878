import * as React from 'react'
import { Flex } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import NavBar from '../components/NavBar'
import { type PropsWithChildren } from 'react'

/**
 * Layout to be used for logged out pages
 */
export const LoggedOutLayout = ({ children }: PropsWithChildren): JSX.Element => (
	<>
		<NavBar/>
		<Flex direction={'column'}>
			{children == null && (<Outlet/>)}
			{children != null && children}
		</Flex>
	</>
)

import React, { Flex, Heading } from '@chakra-ui/react'

export default function Overview (): JSX.Element {
	return (
		<Flex height={'100vh'} justifyContent={'center'} alignItems={'center'}>
			<Heading>
				Welcome to the API Manager
			</Heading>
		</Flex>
	)
}

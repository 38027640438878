import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { router } from './routes'

import { RouterProvider } from 'react-router-dom'
import { ChakraProvider, extendBaseTheme } from '@chakra-ui/react'
import localTheme from './theme'
import { Auth0Provider } from '@auth0/auth0-react'

const theme = extendBaseTheme(localTheme)

const container = document.getElementById('root') ?? undefined
if (container === undefined) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(container)
const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? ''
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''
const redirectUri = `${window.location.origin}${process.env.REACT_APP_AUTH0_REDIRECT_ENDPOINT ?? '/'}`
const audience = process.env.REACT_APP_AUTH0_AUDIENCE ?? ''

root.render(
	<React.StrictMode>
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			authorizationParams={{
				redirect_uri: redirectUri,
				audience
			}}>
			<ChakraProvider theme={theme}>
				<RouterProvider router={router}/>
			</ChakraProvider>
		</Auth0Provider>
	</React.StrictMode>
)

import { useRouteError } from 'react-router-dom'
import React, { Flex, Heading, Text, VStack } from '@chakra-ui/react'

export default function ErrorPage (): JSX.Element {
	const error: any = useRouteError()
	console.error(error)

	return (
		<Flex height={'100vh'} justifyContent={'center'} alignItems={'center'}>
			<VStack>
				<Heading>
					Oops!
				</Heading>
				{error.status !== 404 && (<Text>Sorry, an unexpected error has occurred.</Text>)}
				{error.status === 404 && (<Text>Sorry, page not found</Text>)}
				<Text>
					<i>{error.statusText === undefined ? error.statusText : error.message}</i>
				</Text>
			</VStack>
		</Flex>
	)
}

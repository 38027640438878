import React, { useState, useEffect } from 'react'
import { NavLink, Navigate } from 'react-router-dom'
import { type ChakraProps, Link, ListIcon, Spinner } from '@chakra-ui/react'
import { useRestAPI } from '../../../hooks/useFetch'
import { useAuth0 } from '@auth0/auth0-react'
import { ViewIcon } from '@chakra-ui/icons'

const PERMISSIONS_KEY = 'https://vedadata.com/permissions'
const AUTH_MGMT_VIEW_DOC_CLAIM = process.env.REACT_APP_AUTH_MGMT_VIEW_DOC_CLAIM ?? 'view-documentation:clientApplications'

interface ReadmeJwt {
	signed_payload: string
}

const README_BASE_URL = 'https://developer.vedadata.com'
const README_JWT_SIGNING_ENDPOINT = '/client-applications/sign-readme-jwt'

export function DocLink (props: ChakraProps): JSX.Element {
	const [hasDocViewPermission, setHasDocViewPermission] = useState<boolean>(false)
	const [isSigningToken, setIsSigningToken] = useState<boolean>(false)
	const signReadmePayloadRestAPI = useRestAPI(process.env.REACT_APP_KEY_MGMT_URI ?? '')
	const {
		isAuthenticated,
		getAccessTokenSilently,
		getIdTokenClaims,
		logout
	} = useAuth0()
	const {
		...rest
	} = props

	const openReadmeUrl: () => Promise<void> = async () => {
		getAccessTokenSilently().then(async accessToken => {
			setIsSigningToken(true)
			const requestHeaders = {
				Authorization: 'Bearer ' + accessToken
			}
			const response = await signReadmePayloadRestAPI.post<ReadmeJwt>(README_JWT_SIGNING_ENDPOINT, '{"payload":{}}', requestHeaders)
			if (response.error != null) {
				console.error(response.error)
				logout().catch(console.error)
				return <Navigate to={'/login'} />
			} else {
				const theUrl = `${README_BASE_URL}?auth_token=${response.data?.signed_payload as string}`
				window.open(theUrl, '_self')
			}
		}).catch(console.error)
	}

	function openReadmeDoc (e: { preventDefault: () => void }): void {
		e.preventDefault()
		openReadmeUrl().catch(console.error)
	}

	let icon
	if (isSigningToken) {
		icon = <Spinner height={'20px'} width={'27px'} color="blue.500" />
	} else {
		icon = <ListIcon as={ViewIcon} />
	}

	useEffect(() => {
		if (isAuthenticated) {
			getIdTokenClaims().then(claims => {
				if (claims == null) {
					throw new Error('Token claim is invalid.')
				}
				const permissions: string[] = claims[PERMISSIONS_KEY]
				if (permissions.includes(AUTH_MGMT_VIEW_DOC_CLAIM)) {
					setHasDocViewPermission(true)
				}
			}).catch(console.error)
		}
	}, [isAuthenticated, hasDocViewPermission])

	if (hasDocViewPermission) {
		return (
			<Link as={NavLink} to='readme_placeholder' onClick={openReadmeDoc} {...rest}>
				{icon}
				Documentation
			</Link>
		)
	} else {
		return <></>
	}
}

import * as React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import NavBar from '../components/NavBar'
import PanelContent from '../components/PanelContent'
import { RequiresAuth } from '../components/RequiresAuth'

/**
 * Layout to be used for all pages related to admin pages. Eg. Key management, Users, etc.
 */
export const LoggedInLayout = (): JSX.Element => (
	<RequiresAuth>
		<>
			<NavBar/>
			<Grid templateColumns="repeat(6, 1fr)" bg="gray.50">
				{/* sidebar */}
				<GridItem
					as="aside"
					colSpan={{
						base: 6,
						lg: 2,
						xl: 1
					}}
					bg="gray.50"
					minHeight={{ lg: '100vh' }}
					p={{
						base: '20px',
						lg: '30px'
					}}
				>
					<Sidebar/>
				</GridItem>

				{/* main content & navbar */}
				<GridItem
					as="main"
					colSpan={{
						base: 6,
						lg: 4,
						xl: 5
					}}
					bg="white"
				>

					<PanelContent p="40px">
						<Outlet/>
					</PanelContent>
				</GridItem>
			</Grid>
		</>
	</RequiresAuth>
)

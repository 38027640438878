import { Button, Checkbox, CheckboxGroup, FormControl, FormErrorMessage, FormLabel, HStack, Input, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, Spinner, Textarea } from '@chakra-ui/react'
import React, { useState } from 'react'
import { type Application } from '../list'

const availableScopes: string[] = ['read:providers', 'read:specialties']

interface AppFormProps {
	selectedApplication: Application | null
	setSelectedApplication: (app: React.SetStateAction<Application | null>) => void
	loading: boolean
	handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export function AppForm (props: AppFormProps): JSX.Element {
	const {
		selectedApplication,
		handleSubmit,
		setSelectedApplication,
		loading
	} = props
	const [formErrors, setFormErrors] = useState({
		name: false
	})
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		const {
			name,
			value
		} = event.target
		const errors = { ...formErrors }
		switch (name) {
		case 'name':
			errors.name = false
			if (value === '') {
				errors.name = true
			}
			break
		}
		setFormErrors(errors)
		setSelectedApplication(prevValues => {
			return ({
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				...prevValues!,
				[name]: value
			})
		})
	}

	const handleScopesChange = (newScopes: string[]): void => {
		setSelectedApplication(prevValues => ({
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			...prevValues!,
			scopes: newScopes
		}))
	}

	return (<form onSubmit={handleSubmit}>
		<ModalHeader>{selectedApplication?.id != null ? 'Edit Application' : 'Add Application'}</ModalHeader>
		<ModalCloseButton/>
		<ModalBody>
			<FormControl id="name" mb={4} isRequired isInvalid={formErrors.name}>
				<FormLabel>Name</FormLabel>
				<Input name={'name'} type="text" defaultValue={selectedApplication?.name} onChange={handleInputChange}/>
				<FormErrorMessage>Name is required</FormErrorMessage>
			</FormControl>
			<FormControl id="description" mb={4}>
				<FormLabel htmlFor="description">Description</FormLabel>
				<Textarea name={'description'} defaultValue={selectedApplication?.description} onChange={handleInputChange}/>
			</FormControl>
			<FormControl id="scopes" mb={4}>
				<FormLabel htmlFor="scopes">Scopes</FormLabel>
				<CheckboxGroup value={selectedApplication?.scopes} onChange={handleScopesChange}>
					<HStack spacing="10px">
						{availableScopes.map((scope) => (
							<Checkbox key={scope} value={scope} p="10px">
								{scope}
							</Checkbox>
						))}
					</HStack>
				</CheckboxGroup>
			</FormControl>
		</ModalBody>
		<ModalFooter>
			{loading && (<Spinner/>)}
			{!loading && (
				<Button type="submit" variant={'strong'}>
					Submit
				</Button>
			)}
		</ModalFooter>
	</form>)
}

import { Box, Flex, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import React from 'react'
import { type Application, toastDefaults } from '../list'
import { CopyIcon, DeleteIcon, EditIcon, HamburgerIcon, UnlockIcon, RepeatClockIcon } from '@chakra-ui/icons'

interface AppTableProps {
	applications: Application[] | null
	handleEdit: (app: Application) => void
	handleRotate: (id: number) => void
	getAppAccessToken: (app: Application) => void
	handleDelete: (id: number) => void
	isLoading: boolean
}

const fontLight = { fontWeight: 'lighter' }

export function AppTable (props: AppTableProps): JSX.Element {
	const {
		applications,
		isLoading,
		handleEdit,
		handleRotate,
		handleDelete,
		getAppAccessToken
	} = props
	const toast = useToast()
	const copyToClipboard = (text: string): void => {
		navigator.clipboard.writeText(text).then(() => {
			toast({
				...toastDefaults,
				description: `Copied ${text} to clipboard!`
			})
		}).catch((err: string) => {
			toast({
				...toastDefaults,
				description: `Failed to copy ${text} to clipboard: ${err}`,
				status: 'error'
			})
		})
	}

	return (<Box borderRadius="12px" border="1px solid #E2E8F0">
		{isLoading && (
			<Flex justifyContent="center" alignItems="center" height="100vh">
				<Spinner size="xl" color="blue.500"/>
			</Flex>
		)}
		{!isLoading && (applications != null) && (
			<Table id="keyManagementTable" variant="simple">
				<Thead>
					<Tr>
						<Th>Name</Th>
						<Th>Description</Th>
						<Th>Scopes</Th>
						<Th>Keys</Th>
						<Th width="80px">Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					{applications.map((application: any) => {
						return (
							<Tr key={application.client_id}>
								<Td>{application.name}</Td>
								<Td>{application.description}</Td>
								<Td>{application.scopes.join(', ')}</Td>
								<Td>
									<Flex direction="column">
										<HStack>
											<Text>Client Id: <i style={fontLight}>{application.client_id.substring(0, 5)}*****</i></Text>
											<CopyIcon id={`copyClientIdIcon-${application.name as string}`} cursor="pointer" onClick={() => {
												copyToClipboard(application.client_id)
											}}/>
										</HStack>
										<HStack>
											<Text>Client Secret: <i style={fontLight}>{application.client_secret.substring(0, 5)}*****</i></Text>
											<CopyIcon id={`copyClientSecretIcon-${application.name as string}`} cursor="pointer" onClick={() => {
												copyToClipboard(application.client_secret)
											}}/>
										</HStack>
									</Flex>
								</Td>
								<Td id={`icon-${application.name as string}`}>
									<Menu>
										<MenuButton
											as={IconButton}
											aria-label="Copy"
											icon={<HamburgerIcon/>}
											variant="primary"
											border={'1px solid #E2E8F0'}
											size="sm"
											mr={2}
											ml={2}
										/>
										<MenuList>
											<MenuItem name="menu_list_edit" icon={<EditIcon/>} onClick={() => {
												handleEdit(application)
											}}>
												Edit
											</MenuItem>
											<MenuItem name="menu_list_rotate" icon={<RepeatClockIcon/>} onClick={() => {
												handleRotate(application.client_id)
											}}>
												Rotate secret
											</MenuItem>
											<MenuItem name="menu_list_delete" icon={<DeleteIcon/>} onClick={() => {
												handleDelete(application.client_id)
											}}>
												Delete
											</MenuItem>
											<MenuItem name="menu_list_generate_access_token" icon={<UnlockIcon/>} onClick={() => {
												getAppAccessToken(application)
											}}>
												Generate access token
											</MenuItem>
										</MenuList>
									</Menu>
								</Td>
							</Tr>
						)
					})}
				</Tbody>
			</Table>
		)}
	</Box>)
}

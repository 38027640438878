const activeLinkStyle = {
	borderRadius: '6px',
	bg: '#E2E8F0'
}
export const linkStyles = {
	components: {
		Link: {
			decoration: 'none',
			baseStyle: {
				_hover: {
					textDecoration: 'none',
					...activeLinkStyle
				},
				_activeLink: activeLinkStyle
			}
		}
	}
}

import React from 'react'
import { Flex } from '@chakra-ui/react'

export default function IconBox (props: { [x: string]: any, children: any }): JSX.Element {
	const { children, ...rest } = props

	return (
		<Flex
			alignItems={'center'}
			justifyContent={'center'}
			borderRadius={'12px'}
			{...rest}
		>
			{children}
		</Flex>
	)
}
